export const S = {
    home: {
        welcome: "SpokenLayer",
        title: "Audio for People, by People, powered by AI.",
    },
    publishers: {
        welcome: "SpokenLayer",
        title: "Audio that works.",
        subtitle: "We’re a podcast powerhouse—creating, distributing and monetizing audio content and advertising for some of the biggest publishers and brands.",
    },
    services: {
        title: "What We Do",
    },
    brands: {
        title: "Our Partners",
        text: "From featured series to 24/7 daily news, we deliver high quality and engaging audio for our partners and listeners.",
    },
    podcasts: {
        title: "Audio for Everyone",
        subtitle: "SpokenLayer produces daily and weekly shows on topics from startups to scary movies. Find your show here.",
    }
}