import "./Team.scss"
import {Button} from "../Button"
import {CSSProperties} from "react"
import {chunk} from "../../utils"
import {ReactComponent as Member} from "../../assets/Member.svg"

export const Team = () => {
    const colors = [
        {bg:"var(--Prime-Yellow, #FFF4BF)", hover: "#FFD300"},
        {bg:"var(--Prime-Blue, #BFEFFC)",   hover: "#00C1F3"},
        {bg:"var(--Prime-Pink, #FFBFEA)",   hover: "#F0A"},
        {bg:"var(--Prime-Grey, #BFBFBF)",   hover: "#F5FBFC"},
    ]

    const members = [
        {name:"Yen, Alex", title: "Lead Producer"},
        {name:"Calcado, Ana Beatriz", title: "Creative Operations Project Manager"},
        {name:"Concepcion, Anjeniss", title: "Producer"},
        {name:"Carodine, Bre", title: "HR Manager"},
        {name:"Schellin, Brittany", title: "Senior Writer"},
        {name:"Bonebrake, Bruce", title: "Producer"},
        {name:"Mast, Christine", title: "Accountant"},
        {name:"Balanlayos, Cyrus", title: "Media Operations Director"},
        {name:"Schetter, Dennis", title: "Senior Editor/Producer"},
        {name:"Modica, Elise", title: "Voice Community Manager"},
        {name:"Lackey, Erika", title: "Editor/Project Manager"},
        {name:"Castro, Giancarlo", title: "Producer"},
        {name:"McCarthy, Jessica", title: "VP Of Client Services"},
        {name:"Fritz, Joni", title: "Senior Editor"},
        {name:"Miller, Leah", title: "Creative Operations Project Manager"},
        {name:"Lunoe, Mike", title: "Producer"},
        {name:"Howell, Miranda", title: "Senior Creative Operations Project Manager"},
        {name:"Winiarsky-Reich, Raquel", title: "Product Marketing Lead"},
        {name:"Hou, Stephanie", title: "Producer"},
        {name:"White, Steve", title: "Senior Producer"},
        {name:"Fountain, Thom", title: "Chief Creative Officer"},
        {name:"Gilad, Yaniv", title: "Chief Executive Officer"},
        {name:"Sapir, Yuval", title: "Chief Technology Officer"},
    ]

    return <div className="team">
        <div className="title">Meet Our Team</div>
        <div className="join">
            <div className="left">
                <div className="team-title">Join Our Team and Shape the Future with Us!</div>
                <div className="team-text">At SpokenLayer, we believe in fostering innovation, collaboration, and growth. Whether you're just starting your career or looking for your next challenge, we offer a dynamic environment where you can thrive. Explore our open positions and find your place in a team that values creativity and impact.</div>
            </div>
            <Button text="Join our team" bg="none" color="var(--White, #F5FBFC)" border="2px solid var(--White, #F5FBFC)" bgHover="var(--White, #F5FBFC)"/>

        </div>
        <div className="members">
            {chunk(members, window.screen.width < 480 ? members.length : 6).map((row, i) => <div className="row" key={"row" +i}>
                {row.map((member, j) => <div className="member" key={"member" + j}>
                    <div className="image" style={{"--color":colors[(j + i * 6)%colors.length].bg, "--hover-color":colors[(j + i * 6)%colors.length].hover} as CSSProperties}><Member/></div>
                    <div className="name">{member.name}</div>
                    <div className="job-title">{member.title}</div>
                </div>)}
            </div>)}
        </div>
    </div>
}