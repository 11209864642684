import {Hero} from "../components/publishers/Hero";
import {BestPodcasts} from "../components/publishers/BestPodcasts";
import {Services} from "../components/publishers/Services";
import {Brands} from "../components/publishers/Brands";
import {Testimonials} from "../components/publishers/Testimonials";
import {CTA} from "../components/publishers/CTA";
import {Footer} from "../components/Footer";

import "./Publishers.scss"

export const Publishers = () => {
    const services = [
        { image: 'production.jpeg', number: "6,000+", fact: "podcast episodes made every year across all partners on our network", title: "Production",
            text: "SpokenLayer is a 24/7 content production house, creating daily, weekly, and limited run audio from start to finish. We have writers, reporters, and even playwrights to help with your scripts. We have a roster of hundreds of actors to find your voice—in your language. And of course we have producers, engineers, and composers to put it all together in a best-in-class audio package.",
            tags: ["Content Strategy", "Scriptwriting", "Editing", "Voice Acting", "Audio Engineering", "Sound Design"],
        },
        { image: 'audience.jpeg', number: "35.5M+", fact: "listens each year, spanning the globe", title: "Distribution",
            text: "Once you create great audio—or we do for you—SpokenLayer is here to find your audience. Our team will make sure your podcast hits all major distribution channels and help you find listeners with everything from cross-promotions to social posts.",
            tags: ["Quality Assurance", "Multi-Channel Distribution", "Platform Management", "Social Video Production", "Audience Development"],
        },
        { image: 'monetization.jpeg', number: "34", fact: "active podcasts across all demographic categories", title: "Monetization",
            text: "Our goal is to empower publishers, creators, and brands to make great audio, which takes investment. We can help monetize your content so you can keep reaching your listeners—and brands can find valuable audiences.",
            tags: ["Programmatic Advertising", "Host-Reads", "Ad Production", "Listener Data"],
        },
    ]

    return <div className="home">
        <Hero/>
        <BestPodcasts/>
        <Services services={services} button="Start project"/>
        <Brands/>
        <Testimonials/>
        <CTA/>
        <Footer/>
    </div>
}