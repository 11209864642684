import "./CTA.scss"
import {Button} from "../Button";

export const CTA = () => {
    return <div className="cta">
        <div className="left">
            <div className="title">Become An Advertiser</div>
            <div className="subtitle">Brief message: the benefits of becoming an advertiser with SpokenLayer.</div>
        </div>
        <Button text="Become advertiser" to="/advertisers" bg="none" color="var(--White, #F5FBFC)" border="2px solid var(--White, #F5FBFC)" bgHover="var(--White, #F5FBFC)"/>
    </div>
}