import {ReactComponent as ArrowLeft} from "../assets/arrow-left.svg"

import "./Button.scss"
import {CSSProperties} from "react";
import {Link} from "react-router-dom";

type ButtonProps = {
    text: string,
    to?: string,
    color?: string,
    colorHover?: string,
    bg?: string,
    bgHover?: string,
    border?: string,
    className?: string,
    onClick?: () => void,
}

export const Button = (props:ButtonProps) => {
    const style = {
        "--color":props.color ?? "var(--Primary-Black, #000A18)",
        "--bg":props.bg ?? "var(--Accent-Yellow, #FFEC51)",
        "--border": props.border ?? "2px solid var(--Primary-Black, #000A18)",
        "--color-hover": props.colorHover ?? "var(--Primary-Black, #000A18)",
        "--bg-hover":props.bgHover ?? "var(--Accent-Yellow, #FFEC51)",
    } as CSSProperties

    function onClick() {
        if (props.onClick) {
            props.onClick()
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }

    return <Link to={props.to ?? ""} className={"button " + (props.className ?? "")} onClick={onClick}>
        <div className="text" style={style}>{props.text}</div>
        <div className="arrow" style={style}><ArrowLeft/></div>
    </Link>
}
