import "./Partners.scss"
import {Button} from "../Button";

const brands = [ "Airwave-White.png", "Amazon-White.png", "geminixiii-white.png", "Spotify-White.png" ]
const prevBrands = ["AG1-logo.png", "Apple-Logo.png", "Arbys-Logo.png", "BankofAmerica-logo.png", "audi-logo.png", "burgerking-logo.png",
    "disneyplus-logo.png", "draftkings-logo.png", "ebay-logo.png", "fx-logo.png", "google-logo.png", "intuit-logo.png", "linkedin-logo.png",
    "lulu-logo.png", "mcdonalds-logo.png", "miller-logo.png", "nfl logo.png", "peloton-logo.png", "skims-logo.png", "statefarm-logo.png",
    "tinder-logo.png", "united-logo.png", "walmart-logo.png", "youtube-logo.png", "zillow-logo.png"]

export const Partners = () => {
    return <div className="partners">
        <div className="title">Our Partners</div>
        <div className="frame">
            <div className="left">
                <div className="prompt">We delivered a high-quality audio content</div>
                <Button text="Become partner" bg="none" color="var(--White, #F5FBFC)"
                        border="2px solid var(--White, #F5FBFC)" bgHover="var(--White, #F5FBFC)"/>
            </div>
            <div className="icons">
                {brands.map((b, i) => <div className={"brand"} key={"brand" + i}><img src={`/partners/${b}`}
                                                                                      alt="partner"/></div>)}
            </div>
        </div>
        <div className="prev_title">Brands We’ve Worked With</div>
        <div className="prev_icons">
            <div className="marquee">
                {prevBrands.map((b, i) => <div className={"brand"} key={"partner_1_" + i}><img src={`/partners/${b}`} alt="partner"/></div>)}
                {prevBrands.map((b, i) => <div className={"brand"} key={"partner_2_" + i}><img src={`/partners/${b}`} alt="partner"/></div>)}
            </div>
        </div>
    </div>
}