import "./Demos.scss"
import {ReactComponent as Play} from "../../assets/play.svg"
import {ReactComponent as Pause} from "../../assets/pause.svg"
import {RefObject, useRef, useState} from "react";

let audio = new Audio("")

interface DemosProps {
    refs: Map<string, RefObject<HTMLDivElement>>
}

export function Demos(props: DemosProps) {
    const audioDemos = [
        {id: "salesforce", title: "Salesforce", text: "Dynamic music and production create a moment to make the listener really listen.", file: "Salesforce_Preroll_VT.mp3"},
        {id: "winnebago", title: "Winnebago", text: "Immersive sound design matches the script and puts the listener at ease.", file: "Winnebago_Preroll_VT.mp3"},
        {id: "brooks", title: "Brooks Running", text: "Voice acting shines through with a human performance that sounds like talking to a friend.", file: "Brooks-AudioExample.mp3"},
        {id: "liquidiv", title: "Call Her Daddy + Liquid IV", text: "Open-ended prompts let the host’s personality and endorsement shine through.", file: "CallHerDaddy_LiquidIV_HostRead (2).mp3"},
    ]
    const videoDemos = [
        {id: "transporttopics", title: "Transport Topics", text: "Synthetic voicing creates a quick-turn product that still feels natural and fun.", file: "TransportTopics-AnimationSample.mov", poster: "TransportTopicsPoster.png"},
        {id: "aarp", title:  "AARP Today’s Tips", text: "Bright animation and music composition match the mood of the topics.", file: "AARP-AnimationSample.mp4", poster: "AARPPoster.png"},
    ]

    const [active, setActive] = useState(-1)
    const [playing, setPlaying] = useState(-1)
    const videoRefs = [useRef<HTMLVideoElement>(null), useRef<HTMLVideoElement>(null)]

    function play(track:number) {
        if (track < 0) {
            setPlaying(-1)
            audio.pause()
        } else {
            if (track === playing) {
                audio.pause()
                setPlaying(-1)
            } else {
                audio.src = "/demos/" + audioDemos[track].file
                audio.play()
                setPlaying(track)
            }
        }
        videoRefs.forEach(videoRef => {
            if (videoRef.current)
                videoRef.current.pause()
        })
    }

    function playVideo(track: number){
        setPlaying(-1)
        videoRefs[1-track].current?.pause()
        audio.pause()
    }

    return <div className="demos">
        <div className="title">Hear And See Our Work</div>
        {audioDemos.map((demo, i) => <div className={"audio-player" + (active !== i && active >= 0 ? " inactive":"")} key={`audio${i}`} ref={props.refs.get(demo.id)} onMouseEnter={() => setActive(i)} onMouseLeave={() => setActive(-1)} onClick={() =>play(i)}>
            <div className="button">{playing === i ? <Pause/>:<Play/>}</div>
            <div className="details">
                <div className="audio-title">{demo.title}</div>
                <div className="audio-text">{demo.text}</div>
            </div>
        </div>)}
        <div className="video">
            {videoDemos.map((video, i) => <div className="video-player" key={`video${i}`} ref={props.refs.get(video.id)}>
                <video preload="auto" onPlay={() => playVideo(i)} controls ref={videoRefs[i]} poster={`/demos/${video.poster}`}>
                    <source src={`/demos/${video.file}`}/>
                </video>
                <div className="video-title">{video.title}</div>
                <div className="video-text">{video.text}</div>
            </div>)}

        </div>

    </div>
}