import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Publishers} from "./pages/Publishers";
import {App} from "./App";
import {Home} from "./pages/Home";
import {About} from "./pages/About";
import {Podcasts} from "./pages/Podcasts";
import {Careers} from "./pages/Careers";
import {Work} from "./pages/Work";

const router = createBrowserRouter([
    {path: "/", element: <App/>, children: [
        {path: "/", element: <Home/>},
        {path: "/publishers", element: <Publishers/>},
        {path: "/about", element: <About/>},
        {path: "/podcasts", element: <Podcasts/>},
        {path: "/blog", element: <Publishers/>},
        {path: "/careers", element: <Careers/>},
        {path: "/work", element: <Work/>},
    ]}
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
