import "./Awards.scss"

export const Awards = () => {
    return <div className="awards">
        <div className="title">Out Latest Awards</div>
        <div className="award">
            <div className="left">
                <div className="award-title">Digiday Award for Best Audio Campaign in 2020</div>
                <div className="award-text">The campaign is a scripted adventure for kids, containing interactive
                    elements which determine how the story proceeds. Mindshare collaborated with SpokenLayer in the
                    effort.
                </div>
            </div>
            <img src="/awards/award1.png" alt="award"/>
        </div>
        <div className="award">
            <div className="left">
                <div className="award-title">Digiday Award for Best Audio Campaign in 2020</div>
                <div className="award-text">The campaign is a scripted adventure for kids, containing interactive
                    elements which determine how the story proceeds. Mindshare collaborated with SpokenLayer in the
                    effort.
                </div>
            </div>
            <img src="/awards/award2.jpeg" alt="award"/>
        </div>
    </div>
}