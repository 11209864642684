import {Footer} from "../components/Footer";

import "./About.scss"
import {AboutHero} from "../components/about/AboutHero";
import {Awards} from "../components/about/Awards";
import {Team} from "../components/about/Team";

export const About = () => {
    return <div className="about">
        <AboutHero/>
        <Awards/>
        <Team/>
        <Footer/>
    </div>
}