import "./HomeHero.scss"
import {Button} from "../Button"
import {ReactComponent as Arrow} from "../../assets/arrow down.svg"
import {S} from "../../assets/Strings";
import Spline from "@splinetool/react-spline";

export const HomeHero = () => {
    return <div className="home-hero">
        <div className="heading">
            <Spline className="spline" scene="https://prod.spline.design/cfv7DXVEpGvweuXK/scene.splinecode"/>
            <div className="welcome">{S.home.welcome}</div>
            <div className="title">{S.home.title}</div>
            <Button text="Get Started" to="/work" color="var(--Primary-Black, #000A18)" bg="var(--Accent-Yellow, #FFEC51)" border="none"/>
        </div>
        <div className="more-arrow"><Arrow/></div>
    </div>
}