import Lottie from 'react-lottie'
import * as animation from '../../assets/play-pause-player.json'

import "./BestPodcasts.scss"
import {chunk} from "../../utils";
import {useState} from "react";

interface Podcast {
    image: string
    name: string
    brand: string
    audio?: string
}

let audio = new Audio("")

export function BestPodcasts ()  {
    const podcasts:Array<Podcast> = [
        {image: "EconWorldinBrief.png", name: "The World in Brief", brand: "The Economist", audio: "Econ_Sample60s.mp3"},
        {image: "ThisDayInHistory.png", name: "This Day in History", brand: "The History Channel"},
        {image: "TodaysTipsfromAARP.png", name: "Today’s Tips", brand: "AARP"},
        {image: "Accuweather.png", name: "Accuweather Daily", brand: "Accuweather", audio: "Accuweather-Sample60s.mp3"},
        {image: "ShadowRealm.png", name: "Shadow Realm", brand: "Original Fiction", audio: "ShadowRealm_Sample60s.mp3"},
        {image: "TurboTaxTips.png", name: "TurboTax Tips", brand: "TurboTax"},
        {image: "TC-DailyCrunch.png", name: "The Daily Crunch", brand: "TechCrunch", audio: "DailyCrunch-Sample60s.mp3"},
        {image: "SmallThingsOften.png", name: "Small Things Often", brand: "The Gottman Institute"},
    ]

    const animationOptions = {
        animationData: animation,
        autoplay: false,
        loop: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    }

    const [playing, setPlaying] = useState<string|undefined>(undefined)

    function play(p: Podcast) {
        if (!p.audio)
            return // TODO:remove
        audio.pause()
        if (playing === p.audio) {
            setPlaying(undefined)
        } else {
            if (audio.src !== `/audio/${p.audio}`) {
                audio.src = `/audio/${p.audio}`
            }
            audio.play()
            setPlaying(p.audio)
        }
    }

    return <div className="best-podcasts">
        <div className="title">Our Best Podcasts</div>
        {chunk(podcasts, (window.screen.width > 480 ? 4 : 2)).map((r, i) => <div className="row" key={"row" + i}>
            {r.map((p, j) => <div className="podcast" key={"podcast" + j}>
                <div className="image">
                    <img src={"../../podcasts/" + p.image} alt="Podcast"/>
                    <div className="overlay" onClick={() => play(p)}>
                        <div className="button">
                            <Lottie options={animationOptions} width={50} height={50} isStopped={playing !== p.audio}/>
                        </div>
                    </div>
                </div>
                <div className="name">{p.name}</div>
                <div className="brand">{p.brand}</div>
            </div>)}
        </div>)}
    </div>
}