import "./Brands.scss"
import {chunk} from "../../utils";
import {S} from "../../assets/Strings";

const brands =
    ["aarp.png",
    "accuweather.png",
    "bustle.png",
    "citi.png",
    "dallasmorningnews.png",
    "disney.png",
    "economist.png",
    "engadget.png",
    "fastcompany.png",
    "hearst.png",
    "ign.png",
    "intuit.png",
    // "nydn.png",
    // "ragu.png",
    "sca.png",
    "techcrunch.png",
    "verizon.png"]

export const Brands = () => {
    return <div className="brands">
        <div className="title">{S.brands.title}</div>
        <div className="frame">
            <div className="text">{S.brands.text}</div>
            <div className="icons">
                {chunk(brands, (window.screen.width > 480 ? 5 : 4)).map((r, i) => <div className="row" key={"row" + i}>
                    {r.map((b, i) => <div className={"brand"} key={"brand" + i}>
                        <img src={`/brands/${b}`} alt="brand"/>
                    </div>)}
                </div>)}
            </div>
        </div>
    </div>
}