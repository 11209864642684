import "./Careers.scss"
import {Footer} from "../components/Footer";
import {Button} from "../components/Button";
import {useState} from "react";

export function Careers() {
    const [careersHover, setCareersHover] = useState(false)
    const [voiceHover, setVoiceHover] = useState(false)

    return <div className="careers">
        <div className="hero">
            <div className="title">Join our team and be yourself</div>
            <div className="subtitle">We’re looking for creative, crafty, and community-minded people who believe they’re the best at what they do and have a passion for building the future. Applicants must not be afraid of hard work, urgent deadlines, or working with multi-billion dollar brands.</div>
        </div>
        <div className="forms">
            <div className="careers-form" onMouseEnter={() =>setCareersHover(true)} onMouseLeave={() =>setCareersHover(false)}>
                <div className="title">Careers</div>
                <div className="subtitle">Help us build the future of audio.</div>
                <div className="body">We’re looking for creative, crafty, and community-minded people who believe they’re the best at what they do and have a passion for building the future. Applicants must not be afraid of hard work, urgent deadlines, or working with multi-billion dollar brands.</div>
                <div className="bottom">
                    <Button text="View job posting" bg="none" color={careersHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            border={careersHover ? "2px solid var(--Primary-Black, #000A18)" : "2px solid var(--White, #F5FBFC)"}
                            bgHover={careersHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            colorHover={careersHover ? "var(--White, #F5FBFC)" : "var(--Primary-Black, #000A18)"}/>
                </div>
            </div>
            <div className="voice-form" onMouseEnter={() =>setVoiceHover(true)} onMouseLeave={() =>setVoiceHover(false)}>
                <div className="title">Voice Artists</div>
                <div className="subtitle">Interested in voicing for SpokenLayer?</div>
                <div className="body">Human stories deserve human voices.<br/>We want to hear from you! We are seeking great voice artists who are interested in leveraging their narration and audio production skills.</div>
                <div className="audition">How to Audition</div>
                <div className="body">Fill out the survey below which allows our Creative Hiring Team to learn more about your talent and technical abilities in voice-over. That’s it!</div>
                <div className="bottom">
                    <Button text="Fill Out Survey" bg="none" color={voiceHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            border={voiceHover ? "2px solid var(--Primary-Black, #000A18)" : "2px solid var(--White, #F5FBFC)"}
                            bgHover={voiceHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            colorHover={voiceHover ? "var(--White, #F5FBFC)" : "var(--Primary-Black, #000A18)"}/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}